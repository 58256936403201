<template>
  <div class="text-sm overflow-x-scroll" id="coverTrackFlow" ref="coverTrackFlow">
    <div :style="`width: ${containerWidth}px`">
      <div class="mt-5 w-min m-auto" id="trackFlowContainer" v-if="getArrTrackPoints.length" :key="componentKey">
        <div class="mb-14 w-min">
          <template v-for="(node, period) in getArrTrackPoints" :key="period">
            <div class="mb-14 w-min">
              <a>
                <div
                  :class="`
                    text-center
                    px-4
                    py-3
                    w-lozi-${node.key === currentNode ? '60' : itemWidth}
                    rounded-full
                    bg-white
                    ${node.key === currentNode ? 'bg-blue-600 text-white' : ''}
                  `"
                  :id="`track-point-${node.key}`"
                >
                  {{ node.value.name }}
                </div>
              </a>
            </div>
          </template>
        </div>
      </div>
      <div class="relative" id="leaderLineWrapper">
        <div class="custom-fields-wrapper absolute z-2">
          <div v-for="(line, idx) in lines" :key="idx">
            <div
              :class="`bg-white chips ${!getLineLabel(line.id) ? 'opacity-0' : ''}`"
              :id="`custom-field-list-${line.id}`"
            >
              <span class="flex flex-row justify-center">
                {{ getLineLabel(line.id) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  IBarcode,
  IBarcodeDefinitionType,
  ILineGravity,
  ITrackingData,
  ITrackpoint,
  ITrackPointForms,
  ITrackPointKeyVal,
  TTrackingDataCount,
} from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import LeaderLine, { SocketType } from 'vue3-leaderline'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { sortTrackingPoints } from '@/utils/helpers'
import BarcodeTypesMixin from '../mixins/BarcodeTypesMixin.vue'
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'
export interface IRoute {
  from: string
  to: string
}

@Options({
  name: 'TrackedDataFlow',
})
export default class TrackedDataFlow extends mixins(BarcodeTypesMixin) {
  @Prop({ type: Object }) readonly barcode?: IBarcode
  @Prop({ type: Object }) readonly arrTrackPoints?: ITrackPointKeyVal[]
  @Prop({ type: Boolean }) readonly isEnableLabel?: boolean
  @Prop({ type: Object }) readonly arrTrackPointLabel?: TTrackingDataCount[]
  componentKey = 0
  itemWidth = 32
  containerWidth = 320
  lines: {
    id: string
    form: ITrackPointForms
  }[] = []

  lineGravity = {
    left: 0,
    right: 0,
    auto: 0,
  } as ILineGravity

  getLineLabel(lineId: string) {
    const startNodeKey = lineId.split('-')[0]
    const endNodeKey = lineId.split('-')[1]
    const trackingPointData = this.arrTrackPointLabel?.find((el) => el.trackPointKeyFrom === startNodeKey)
    const trackingCountsFromData = trackingPointData?.trackingCounts.find((el) => el.trackPointKeyTo === endNodeKey)
    const count = trackingCountsFromData?.count
    return count && count > 99 ? '99+' : count
  }

  get getArrTrackPoints(): ITrackPointKeyVal[] {
    if (!this.barcode && this.arrTrackPoints && this.arrTrackPoints.length > 0) {
      return this.arrTrackPoints
    }
    const bcType: IBarcodeDefinitionType = this.currentBarcodeTypeInfo(this.barcode)
    this.trackingPointDatas = cloneDeep(this.$store.state.project?.details.trackPoints)
    let arrTrackPoints = objectToArray<ITrackpoint>(this.trackingPointDatas) || []
    const originalArrTracKPoints = cloneDeep(arrTrackPoints)
    sortTrackingPoints(arrTrackPoints)
    try {
      const startTP = arrTrackPoints.find((tp) => tp.value.isStart)?.key
      const S = [...(!isEmpty(bcType.overridableStartTrackPoints) ? bcType.overridableStartTrackPoints : [startTP])]
      const T = [] as string[]
      const availRoutes = [] as IRoute[]

      const restrictedRoutes = bcType.trackPointRouteRestrictedPaths ?? []
      while (!isEmpty(S)) {
        const item = S.pop()
        if (!item || T.includes(item)) continue
        T.push(item)
        const tpItem = arrTrackPoints.find((i) => i.key === item)
        if (!tpItem) continue
        const forms = tpItem.value.trackPointForms ?? {}
        Object.keys(forms).forEach((toKey) => {
          const isRestricted = restrictedRoutes.find((rr) => rr.from === item && rr.to === toKey)
          if (isRestricted) return
          availRoutes.push({
            from: item,
            to: toKey,
          })
          S.push(toKey)
        })
      }

      arrTrackPoints.forEach((tp) => {
        const fromKey = tp.key
        const forms = tp.value.trackPointForms ?? {}
        Object.keys(forms).forEach((toKey) => {
          const availRoute = availRoutes.find((availRoute) => availRoute.from === fromKey && availRoute.to === toKey)
          if (!availRoute) delete forms[toKey]
        })
      })
    } catch (e) {
      arrTrackPoints = originalArrTracKPoints
    }

    return arrTrackPoints
  }

  get currentNode() {
    return this.barcode?.currentTrackPointKey
  }

  get trackingDatas(): ITrackingData[] {
    if (this.barcode?.trackingData && this.barcode?.trackingData?.length) {
      return Object.values(this.barcode.trackingData)
    }

    return []
  }

  getDefaultLineGravity() {
    return {
      left: 0,
      right: 0,
      auto: 0,
    }
  }

  drawLeaderLine() {
    // scroll to middle
    const containerWidth = this.$refs.coverTrackFlow.clientWidth

    const dataTrackPoint = this.getArrTrackPoints
    const leaderLineWrapper = document.getElementById('leaderLineWrapper')
    if (leaderLineWrapper) {
      this.lineGravity = this.getDefaultLineGravity()
      this.startDraw(dataTrackPoint)
      this.fixWrapperPosition(leaderLineWrapper)
      this.moveLines(leaderLineWrapper)
      const containerEle = document.getElementById('trackFlowContainer')
      if (containerEle) {
        this.strWidthTrackFlowContainer = `width: ${containerEle.clientWidth}px`
      }
    }

    const scrollToX = (this.containerWidth - containerWidth) / 2
    const coverEle = document.getElementById('coverTrackFlow')
    if (coverEle) {
      coverEle.scrollTo(scrollToX, 0)
    }

    setTimeout(() => {
      // Enable middle label
      if (this.isEnableLabel) {
        this.moveLabels()
      } else {
        this.removeLeaderLineDefaultLabel()
      }
    }, 0)

    return new Promise((resolve) => {
      setTimeout(() => resolve(1))
    })
  }

  async fixWrapperPosition(leaderLineWrapper: HTMLElement) {
    const rectWrapper = leaderLineWrapper.getBoundingClientRect()

    // prevent second translating LeaderLine loading on detail page
    // (customHTML redirect -> detail page make leaderline wrong position)
    const x = rectWrapper.left + pageXOffset
    const y = rectWrapper.top + pageYOffset
    if (x === 0 && y === 0) return

    leaderLineWrapper.style.transform = `translate(-${x}px, -${y}px)`
  }

  async moveLines(leaderLineWrapper: HTMLElement) {
    const lines = document.querySelectorAll('svg.leader-line')
    for (const line of lines) {
      leaderLineWrapper.appendChild(line)
    }
  }

  startDraw(dataTrackPoint: ITrackPointKeyVal[] = []) {
    let isPrev1stPos = false

    let lines = {
      rightLines: [] as number[][],
      leftLines: [] as number[][],
      rightLevel: 1,
      leftLevel: 1,
    }

    const roundTripPaths = [] as number[][]
    dataTrackPoint.forEach((startItem) => {
      const startIdx = Number(startItem.key)
      const endIdxs = Object.keys(startItem.value.trackPointForms ?? {})
      endIdxs
        .filter((endIdx) => !roundTripPaths.find(([s, e]) => s === Number(endIdx) && e === startIdx))
        .filter(
          (endIdx) =>
            !!dataTrackPoint.find(
              (endItem) =>
                endItem.key === endIdx && Object.keys(endItem.value.trackPointForms ?? {}).includes(startItem.key)
            )
        )
        .forEach((endIdx) => roundTripPaths.push([Number(startItem.key), Number(endIdx)]))
    })

    dataTrackPoint.map((node, index) => {
      const startIdx = Number(node.key)
      const startNode = document.getElementById(`track-point-${node.key}`)
      if (!startNode) return

      const trackingPointForms = node.value.trackPointForms || {}
      for (const [nextNodeKey, nextTrackPoint] of Object.entries(trackingPointForms)) {
        const endIdx = Number(nextNodeKey)
        const endNode = document.getElementById(`track-point-${nextNodeKey}`)
        if (!endNode) continue

        const indexNextNode = dataTrackPoint.findIndex((item) => item.key === nextNodeKey)

        let color = '#cecece'
        for (const item of this.trackingDatas) {
          if (item.currentTrackPointKey === node.key && item.nextTrackPointKey === nextNodeKey) {
            color = '#2564eb'
            break
          }
        }

        const position = this.getConnectPosition(index, indexNextNode, isPrev1stPos) as SocketType
        let lvl = 0
        if (position !== 'auto') {
          isPrev1stPos = position === 'right'

          const curMin = Math.min(startIdx, endIdx)
          const keyPos = isPrev1stPos ? 'right' : 'left'

          const linesArr = lines[`${keyPos}Lines` as 'rightLines' | 'leftLines'] as number[][]
          lines = {
            ...lines,
            [`${keyPos}Level` as 'rightLevel' | 'leftLevel']:
              (lines[`${keyPos}Level` as 'rightLevel' | 'leftLevel'] as number) +
              (linesArr.find((n) => curMin <= Math.max(n[0], n[1])) ? 1 : 0),
          }
          lvl = lines[`${keyPos}Level` as 'rightLevel' | 'leftLevel'] as number
          linesArr.push([startIdx, endIdx])
        }

        let startPoint, endPoint
        if (position === 'auto') {
          const isGo = roundTripPaths.find(([s, e]) => s === startIdx && e === endIdx)
          const isReturn = roundTripPaths.find(([s, e]) => e === startIdx && s === endIdx)
          if (isGo) {
            startPoint = LeaderLine.pointAnchor(startNode, {
              x: '40%',
              y: '100%',
            })
            endPoint = LeaderLine.pointAnchor(endNode, { x: '40%', y: '0%' })
          } else if (isReturn) {
            startPoint = LeaderLine.pointAnchor(startNode, {
              x: '60%',
              y: '0%',
            })
            endPoint = LeaderLine.pointAnchor(endNode, { x: '60%', y: '100%' })
          }
        }

        const socketGravity = 20 * lvl
        const lineIdx = `${node.key}-${nextNodeKey}`
        ;(() =>
          new LeaderLine(startPoint ?? startNode, endPoint ?? endNode, {
            lineId: `TPS_leaderLine_${lineIdx}`,
            size: 3,
            path: position !== 'auto' ? 'grid' : 'straight',
            color: color,
            startSocket: position,
            endSocket: position,
            startSocketGravity: socketGravity,
            middleLabel: LeaderLine.captionLabel(lineIdx, {
              color: 'grey',
            }),
            endSocketGravity: socketGravity,
            endPlug: 'arrow2',
          }))()

        this.lines.push({
          id: lineIdx,
          form: nextTrackPoint,
        })
      }
    })
    return new Promise((resolve) => {
      setTimeout(() => resolve(1))
    })
  }

  removeLeaderLineDefaultLabel() {
    const leaderLineWrapper = document.getElementById('leaderLineWrapper')
    if (leaderLineWrapper) {
      const lines = leaderLineWrapper.querySelectorAll('svg.leader-line')
      lines.forEach((line) => {
        if (!line.id.includes('TPS_leaderLine')) return
        const label = line.getElementsByTagName('text')[0]
        label.innerHTML = ''
      })
    }
  }

  moveLabels() {
    const leaderLineWrapper = document.getElementById('leaderLineWrapper')
    if (leaderLineWrapper) {
      const lines = leaderLineWrapper.querySelectorAll('svg.leader-line')
      lines.forEach((line, idx) => {
        if (!line.id.includes('TPS_leaderLine')) return

        const label = line.getElementsByTagName('text')[0]
        const lineData = this.lines[idx]

        if (!lineData) return
        const customFieldList = document.getElementById(`custom-field-list-${lineData.id}`)
        if (label.parentNode !== null && customFieldList !== null) {
          const rect = label.getBoundingClientRect()
          const leaderLineRect = line.getBoundingClientRect()
          customFieldList.style.position = 'absolute'
          customFieldList.style.zIndex = '10000'
          const customFieldHeight = customFieldList.offsetHeight
          const customFieldWidth = customFieldList.offsetWidth

          customFieldList.style.top = `${leaderLineRect.top + leaderLineRect.height / 2 - customFieldHeight / 2}px`
          customFieldList.style.left = `${rect.left + rect.width / 2 - customFieldWidth / 2}px`
        }
        label.innerHTML = ''
      })
    }
  }

  setContainerWidth(dataTrackPoint: ITrackPointKeyVal[] = []) {
    let lineGravityData = this.getDefaultLineGravity()
    let isPrev1stPos = false
    dataTrackPoint.map((node, index) => {
      const trackingPointForms = node.value.trackPointForms || {}
      for (const [nextNodeKey] of Object.entries(trackingPointForms)) {
        const indexNextNode = dataTrackPoint.findIndex((item) => {
          return item.key === nextNodeKey
        })

        const resultCal = this.calculateLeftRightRange(index, indexNextNode, lineGravityData, isPrev1stPos)
        if (resultCal.position !== 'auto') {
          isPrev1stPos = resultCal.position === 'right'
        }

        lineGravityData = resultCal.lineGravityData
      }
    })

    const maxSideWidth = lineGravityData.left > lineGravityData.right ? lineGravityData.left : lineGravityData.right
    const maxContainerWidth = maxSideWidth * 2 + this.itemWidth * 4 + 40
    if (this.containerWidth < maxContainerWidth) {
      this.containerWidth = maxContainerWidth
    }
  }

  getConnectPosition(current: number, next: number, isPrev1stPos: boolean): string {
    const resultCal = this.calculateLeftRightRange(current, next, this.lineGravity, isPrev1stPos)

    this.lineGravity = resultCal.lineGravityData
    return resultCal.position
  }

  calculateLeftRightRange(current: number, next: number, lineGravityData: ILineGravity, isPrev1stPos: boolean) {
    const nextHeight = 20

    const currents = [current + 1, current - 1]
    let position = 'auto'
    if (!currents.includes(next)) {
      if (isPrev1stPos) {
        position = 'left'
        lineGravityData.right += nextHeight
      } else {
        position = 'right'
        lineGravityData.left += nextHeight
      }
    }

    return {
      position,
      lineGravityData,
    }
  }

  created() {
    // set Container Width
    this.setContainerWidth(this.getArrTrackPoints)
  }
}
</script>
<style lang="scss" scoped>
.chips {
  width: 38px;
  height: 21px;
  color: white;
  background: #979797;
  border-radius: 21px;
}
</style>
